"use client";

import React, { Suspense, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "next/image";
import { FaBars, FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import styles from "./topNavbar.module.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import SearchProducts from "@/components/SearchProducts/SearchProducts";
import { SitesCategories } from "@/common/entities/sites/SiteCategories";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { AwsFile } from "@/common/entities/files/AwsFiles";
import { FiPhone, FiSearch, FiShoppingCart } from "react-icons/fi";
import { SitesBasic } from "@/common/entities/sites/SitesBasic";

export interface NavbarDataLink {
    title: string;
    route: string;
}
interface MobileNavBarProps {
    sitesCategories: SitesCategories[];
    phone?: string;
    logo?: AwsFile | undefined;
    siteDetails?: SitesBasic;
}

const MobileNavBar: React.FC<MobileNavBarProps> = ({ sitesCategories, phone, logo, siteDetails }) => {
    const [show, setShow] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const router = useRouter();

    const toggleSearch = () => setShowSearch(!showSearch);

    const handleIconClick = () => {
        router.push("/cart");
    };

    return (
        <>
            <div className={`${styles.topNavbarWrapper} ${styles.sticky} ${styles.mobileNavBar}`}>
                <Container className={styles.brandContainer}>
                    <Row className="d-flex justify-content-center align-items-center py-3">
                        <Col>
                            <FaBars onClick={() => setShow(true)} />
                        </Col>
                        <Col xs={6}>
                            {logo && (
                                <Link href="/">
                                    <Image
                                        src={process.env.NEXT_PUBLIC_AWS_FILES_URL + logo?.originalFileName}
                                        alt={process.env.NEXT_PUBLIC_DOMAIN_URL ?? ""}
                                        sizes="100vw"
                                        width={220}
                                        height={60}
                                        unoptimized
                                        priority
                                        className={styles.logoTopNav}
                                    />
                                </Link>
                            )}
                        </Col>
                        <Col>
                            <FiSearch onClick={toggleSearch} />
                        </Col>
                        <Col>
                            {phone && (
                                <a aria-label="Phone contact" className="text-dark" href={`tel:${phone}`}>
                                    <FiPhone />
                                </a>
                            )}
                        </Col>
                        <Col>
                            <FiShoppingCart onClick={handleIconClick} />
                        </Col>
                    </Row>
                    {showSearch && (
                        <Row className="pb-3">
                            <Suspense>
                                <SearchProducts />
                            </Suspense>
                        </Row>
                    )}
                </Container>
            </div>

            <Offcanvas className={styles.mobileOffCanvas} show={show} onHide={() => setShow(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Categorii produse</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="px-0 pt-0">
                    <div className={styles.mobileWrapperMenu}>
                        {sitesCategories &&
                            sitesCategories.map((category, categoryIndex: number) => (
                                <Link
                                    key={categoryIndex}
                                    href={"/" + (category.redirectUrl ?? category.category.url)}
                                    className={styles.itemMenuMobile}
                                    passHref
                                    onClick={() => setShow(false)}
                                >
                                    {category.category.name}
                                </Link>
                            ))}
                    </div>

                    <div className={styles.secondWrapperMenu}>
                        <Link href={"/promotii"} className={styles.itemMenuMobile} passHref onClick={() => setShow(false)}>
                            Promotii
                        </Link>
                        <Link href={"/blog"} className={styles.itemMenuMobile} passHref onClick={() => setShow(false)}>
                            Blog
                        </Link>
                        <Link href={"/galerie"} className={styles.itemMenuMobile} passHref onClick={() => setShow(false)}>
                            Galerie
                        </Link>
                        <Link href={"/contact"} className={styles.itemMenuMobile} passHref onClick={() => setShow(false)}>
                            Contact
                        </Link>
                    </div>

                    <div className={styles.mobileSocials}>
                        {siteDetails?.youtubeLink && (
                            <Col xs={2} md={2}>
                                <Link target="_blank" href={siteDetails.youtubeLink} passHref rel="noopener noreferrer">
                                    <FaYoutube />
                                </Link>
                            </Col>
                        )}
                        {siteDetails?.facebookLink && (
                            <Link target="_blank" href={siteDetails.facebookLink} passHref rel="noopener noreferrer">
                                <FaFacebookF />
                            </Link>
                        )}
                        {siteDetails?.instagramLink && (
                            <Link target="_blank" href={siteDetails.instagramLink} passHref rel="noopener noreferrer">
                                <FaInstagram />
                            </Link>
                        )}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
export default MobileNavBar;
