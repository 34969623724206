"use client";

import { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import styles from "./topNavbar.module.scss";
import { SitesCategories } from "@/common/entities/sites/SiteCategories";

interface ProductMenuProps {
    sitesCategories: SitesCategories[];
    textMenu?: string;
}

const ProductMenu: React.FC<ProductMenuProps> = ({ sitesCategories, textMenu }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const closeTimer = useRef<NodeJS.Timeout | null>(null);

    const handleMouseEnter = () => {
        if (closeTimer.current) {
            clearTimeout(closeTimer.current);
            closeTimer.current = null;
        }
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        closeTimer.current = setTimeout(() => {
            setShowDropdown(false);
        }, 600);
    };

    useEffect(() => {
        const node = dropdownRef.current;

        if (node) {
            node.addEventListener("mouseenter", handleMouseEnter);
            node.addEventListener("mouseleave", handleMouseLeave);

            return () => {
                node.removeEventListener("mouseenter", handleMouseEnter);
                node.removeEventListener("mouseleave", handleMouseLeave);

                if (closeTimer.current) {
                    clearTimeout(closeTimer.current);
                }
            };
        }
    }, []);

    return (
        <Dropdown className={styles.dropdownMenuProd} show={showDropdown} ref={dropdownRef}>
            <Dropdown.Toggle className={styles.productMenuButton} id="dropdown-basic">
                <FaBars />
                {textMenu && <span className="ms-3">{textMenu}</span>}
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.productMenuContent}>
                {sitesCategories &&
                    sitesCategories.map((category, categoryIndex: number) => (
                        <Dropdown.Item
                            href={"/" + (category.redirectUrl ?? category.category.url)}
                            className={styles.productMenuEntry}
                            key={categoryIndex}
                            title={category.name}
                        >
                            <span>{category.name}</span>
                        </Dropdown.Item>
                    ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ProductMenu;
