"use client";

import React, { PropsWithChildren, useState } from "react";
import { useServerInsertedHTML } from "next/navigation";
import { createGlobalStyle, ServerStyleSheet, StyleSheetManager } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --primary-color: ${(props: DynamicStyles) => props.primaryColor ?? "rgb(242 125 20)"};
        --secondary-color: ${(props: DynamicStyles) => props.secondaryColor ?? "rgb(242 125 20)"};
        --secondary-background-color: ${(props: DynamicStyles) => props.secondaryBackgroundColor ?? "rgb(242 125 20)"};
        --primary-background-color: ${(props: DynamicStyles) => props.primaryBackgroundColor ?? "rgb(242 125 20)"};
    }
`;

interface DynamicStyles {
    primaryColor: string;
    secondaryColor: string;
    secondaryBackgroundColor: string;
    primaryBackgroundColor: string;
}

export default function StyledComponentsRegistry({
    children,
    primaryColor,
    secondaryColor,
    secondaryBackgroundColor,
    primaryBackgroundColor,
}: PropsWithChildren<DynamicStyles>) {
    const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet());

    useServerInsertedHTML(() => {
        const styles = styledComponentsStyleSheet.getStyleElement();
        styledComponentsStyleSheet.instance.clearTag();
        return <>{styles}</>;
    });

    if (typeof window !== "undefined") return <>{children}</>;

    return (
        <StyleSheetManager sheet={styledComponentsStyleSheet.instance}>
            <GlobalStyle
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                primaryBackgroundColor={primaryBackgroundColor}
                secondaryBackgroundColor={secondaryBackgroundColor}
            />
            {children}
        </StyleSheetManager>
    );
}
