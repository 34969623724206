import React from "react";
import { ProductCategory } from "@/common/entities/product/ProductCategory";
import style from "@/components/SearchProducts/searchProducts.module.scss";
import Link from "next/link";

interface SearchProductsTitleAndCategoryProps {
    title: string;
    productCategories?: ProductCategory[];
    productCode: string;
    categoryLink: string;
    productLink: string;
    categoryName: string;
}

const SearchProductsTitleAndCategory = ({
    productLink,
    categoryLink,
    title,
    productCode,
    categoryName,
}: SearchProductsTitleAndCategoryProps) => {
    return (
        <div className="d-flex flex-column ms-2">
            <Link href={productLink}>
                <b className={style.productName}>{title}</b>
            </Link>
            <Link href={"/" + categoryLink}>
                <span className={style.productCategory}>{categoryName}</span>
            </Link>
            <Link href={productLink}>
                <span className={style.productReference}>{productCode}</span>
            </Link>
        </div>
    );
};

export default SearchProductsTitleAndCategory;
