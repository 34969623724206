"use client";

import { Accordion, Col, Row } from "react-bootstrap";
import Link from "next/link";
import { FaFacebookF, FaInstagram, FaYoutube, FaTiktok } from "react-icons/fa";
import styles from "./footer.module.scss";
import Image from "next/image";
import { FooterProps } from "./footer";
import { LinkPosition } from "@/common/entities/sites/SitePages";
import AnpcSal from "../../../images/anpc-sal.webp";
import AnpcSol from "../../../images/anpc-sol.webp";

const FooterMobile: React.FC<FooterProps> = ({ siteDetails, logo, footerData = [], pages }) => {
    return (
        <>
            <Accordion defaultActiveKey="0" className={`${styles.footerAccordion} ${!siteDetails ? styles.sticky : ""}`}>
                <Accordion.Item eventKey="0" className={styles.accordionItem}>
                    <Accordion.Header className={styles.accordionHeader}>Despre noi</Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                        <Row>
                            <Col>
                                <div className={styles.innerColFooter}>
                                    {logo && (
                                        <Image
                                            className={styles.footerLogo}
                                            src={process.env.NEXT_PUBLIC_AWS_FILES_URL + logo?.originalFileName}
                                            alt="logo"
                                            width={220}
                                            height={60}
                                            unoptimized
                                            priority
                                            style={{ width: "auto", height: "auto" }}
                                        />
                                    )}
                                    <div className="mt-4">
                                        {siteDetails.shortDescription && <p className={styles.footerInfo}>{siteDetails.shortDescription}</p>}
                                    </div>

                                    <div className={styles.footerSocialLinks}>
                                        {siteDetails.facebookLink && (
                                            <Link target="_blank" title="facebook" href={siteDetails.facebookLink} passHref rel="noopener noreferrer">
                                                <FaFacebookF />
                                            </Link>
                                        )}
                                        {siteDetails.instagramLink && (
                                            <Link target="_blank" title="Instagram" href={siteDetails.instagramLink} passHref rel="noopener noreferrer">
                                                <FaInstagram />
                                            </Link>
                                        )}
                                        {siteDetails.youtubeLink && (
                                            <Link target="_blank" title="youtube" href={siteDetails.youtubeLink} passHref rel="noopener noreferrer">
                                                <FaYoutube />
                                            </Link>
                                        )}
                                        {siteDetails.tiktokLink && (
                                            <Link target="_blank" title="tiktok" href={siteDetails.tiktokLink} passHref rel="noopener noreferrer">
                                                <FaTiktok />
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item className={styles.accordionItem} eventKey="1">
                    <Accordion.Header className={styles.accordionHeader}>Informatii utile</Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                        <Row>
                            <Col>
                                <div className={styles.innerColFooter}>
                                    {footerData.map((mainLink, index: number) => (
                                        <div key={index} className={styles.footerLink}>
                                            <Link href={mainLink.route}>{mainLink.title}</Link>
                                        </div>
                                    ))}
                                    {pages?.data
                                        .filter((page) => page.linkPosition === LinkPosition.Footer && page.active)
                                        .map((page, index: number) => (
                                            <div key={index} className={styles.footerLink}>
                                                <Link href={`/${page.url}`} title={page.metaTitle}>
                                                    {page.title}
                                                </Link>
                                            </div>
                                        ))}
                                </div>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item className={styles.accordionItem} eventKey="2">
                    <Accordion.Header className={styles.accordionHeader}>Informatii comerciale</Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                        <Row>
                            <Col>
                                <div className={styles.innerColFooter}>
                                    <div className={styles.footerLink}>
                                        <Link href="/termeni-si-conditii">Termeni si conditii</Link>
                                    </div>
                                    <div className={styles.footerLink}>
                                        <Link href="/politica-de-utilizare-cookie">Politica cookie-uri</Link>
                                    </div>
                                    <div className={styles.footerLink}>
                                        <Link href="/conditii-de-transport">Conditii de transport</Link>
                                    </div>
                                    <div className={styles.footerLink}>
                                        <Link href="/politica-de-confidentialitate">Politica de confidentialitate</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item className={styles.accordionItem} eventKey="3">
                    <Accordion.Header className={styles.accordionHeader}>Contact</Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                        <Row>
                            <Col>
                                <div className={styles.innerColFooter}>
                                    <ul className={styles.companyInfo}>
                                        {siteDetails.phone && (
                                            <li>
                                                <a rel="noreferrer" target="_blank" href={`tel:${siteDetails.phone}`}>
                                                    {siteDetails.phone}
                                                </a>
                                            </li>
                                        )}
                                        {siteDetails.email && (
                                            <li>
                                                <a rel="noreferrer" target="_blank" href={`mailto:${siteDetails.email}`}>
                                                    {siteDetails.email}
                                                </a>
                                            </li>
                                        )}
                                        {siteDetails.address && (
                                            <li>
                                                <span>{siteDetails.address}</span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <div className={`${!siteDetails ? styles.footerMobileImages : "d-flex align-items-center justify-content-center mt-2"}`}>
                <a className="d-inline-block" href="https://anpc.ro/ce-este-sal/" title="" target="_blank" rel="noopener noreferrer nofollow">
                    <Image unoptimized width="170" height="45" src={AnpcSal} alt="Solutionarea alternativa a litigiilor" />
                </a>
                <a
                    className="d-inline-block ms-2 ms-md-0"
                    href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=RO"
                    title=""
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                >
                    <Image unoptimized width="170" height="45" src={AnpcSol} alt="Solutionarea oline a litigilor" />
                </a>
            </div>
        </>
    );
};

export default FooterMobile;
