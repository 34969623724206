"use client";

import React, { Suspense, useEffect, useState } from "react";
import { Button, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import Image from "next/image";
import { FiPhone } from "react-icons/fi";
import ProductMenu from "./ProductMenu";
import styles from "./topNavbar.module.scss";
import SearchProducts from "@/components/SearchProducts/SearchProducts";
import Link from "next/link";
import NavbarCartButton from "@/components/layout/navbar/NavbarCartButton";
import { SitesCategories } from "@/common/entities/sites/SiteCategories";
import { AwsFile } from "@/common/entities/files/AwsFiles";
import { LinkPosition, SitesPages } from "@/common/entities/sites/SitePages";

export interface NavbarDataLink {
    title: string;
    route: string;
}

const navbarData: NavbarDataLink[] = [
    {
        title: "Promotii",
        route: "/promotii",
    },
    {
        title: "Blog",
        route: "/blog",
    },
    {
        title: "Galerie",
        route: "/galerie",
    },
    {
        title: "Contact",
        route: "/contact",
    },
];

interface AppNavbarProps {
    sitesCategories: SitesCategories[];
    logo?: AwsFile | undefined;
    phone?: string;
    pages?: SitesPages[];
}

const AppNavbar: React.FC<AppNavbarProps> = ({ sitesCategories, logo, pages, phone }) => {
    const [showCartDetails, setShowCartDetails] = useState(false);
    const [sticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            if (!sticky && scrollPosition > 400) {
                setSticky(true);
            } else if (sticky && scrollPosition < 360) {
                setSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [sticky]);

    return (
        <>
            <div className={`${styles.topNavbarWrapper} ${sticky ? styles.sticky : ""}`}>
                <Container className={styles.brandContainer}>
                    <Row className={`d-flex justify-content-center align-items-center ${sticky ? "py-2" : "py-4"}`}>
                        {sticky && (
                            <Col md={1}>
                                <ProductMenu sitesCategories={sitesCategories} />
                            </Col>
                        )}
                        <Col md={3}>
                            <Link href="/">
                                {logo && (
                                    <Image
                                        src={process.env.NEXT_PUBLIC_AWS_FILES_URL + logo?.originalFileName}
                                        alt={logo?.altName}
                                        width={220}
                                        height={60}
                                        unoptimized
                                        priority
                                    />
                                )}
                            </Link>
                        </Col>
                        <Col md={sticky ? 6 : 7} className={styles.searchContainer}>
                            <Suspense>
                                <SearchProducts />
                            </Suspense>
                        </Col>
                        <Col md={2}>
                            <Nav.Link
                                className={styles.cartButton}
                                onMouseEnter={() => setShowCartDetails(true)}
                                onMouseLeave={() => setShowCartDetails(false)}
                            >
                                <NavbarCartButton showCartDetails={showCartDetails} />
                            </Nav.Link>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={styles.menuWrapper}>
                <Navbar className={styles.menu}>
                    <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="d-flex align-items-center me-auto">
                                <ProductMenu textMenu="Produse" sitesCategories={sitesCategories} />
                            </Nav>
                            {navbarData.map((mainLink, mainLinksIndex: number) => (
                                <React.Fragment key={mainLinksIndex}>
                                    <Nav.Link as="div">
                                        <Link title={mainLink.title} href={mainLink.route} passHref>
                                            {mainLink.title}
                                        </Link>
                                    </Nav.Link>
                                </React.Fragment>
                            ))}
                            {pages &&
                                pages
                                    .filter((pages) => pages.linkPosition === LinkPosition.Header && pages.active === true)
                                    .map((page, mainLinksIndex: number) => (
                                        <React.Fragment key={mainLinksIndex}>
                                            <Nav.Link as="div">
                                                <Link className={styles.LinkTop} title={page.metaTitle} href={"/" + page.url} passHref>
                                                    {page.title}
                                                </Link>
                                            </Nav.Link>
                                        </React.Fragment>
                                    ))}

                            {phone && (
                                <Button className={styles.contact} size="sm">
                                    <FiPhone />
                                    <a target="_blank" href={`tel:${phone}`} className="ms-2 text-white">
                                        {phone}
                                    </a>
                                </Button>
                            )}
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    );
};
export default AppNavbar;
