"use client";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import styles from "@/components/layout/navbar/topNavbar.module.scss";
import FloatingCart from "@/components/FloatingCart/page";
import LocalStorageService from "@/services/localStorageService/LocalStorageService";
import { LocalStorageKeys } from "@/services/localStorageService/LocalStorageKeys";
import { CartItem } from "@/common/entities/cart/CartItem";
import { FiShoppingCart } from "react-icons/fi";
import { ProductType } from "@/common/entities/product/Product";
import { generateFullCheckoutCart } from "@/common/helpers/util.functions";
import { CheckoutCart } from "@/components/Cart/CartForm/CartForm";

interface NavbarCartButtonProps {
    showCartDetails: boolean;
}

const NavbarCartButton = ({ showCartDetails }: NavbarCartButtonProps) => {
    const [totalProducts, setTotalProducts] = useState(0);
    const cartLocalStorage = generateFullCheckoutCart(LocalStorageService.getItem(LocalStorageKeys.cart));
    const [cart, setCart] = useState<CheckoutCart | null>(cartLocalStorage);

    useEffect(() => {
        let total =
            cart?.cartProducts?.reduce((acc, item: CartItem) => {
                if (item.originConfiguratorCode) {
                    return acc;
                }

                if ([ProductType.glass, ProductType.door].includes(item.product.type)) {
                    return acc + 1;
                }

                return acc + Number(item.quantity);
            }, 0) || 0;

        total += cart?.configurators?.length || 0;
        setTotalProducts(total);
    }, [cart]);

    useEffect(() => {
        const handleStorageUpdate = () => {
            const updatedCart = LocalStorageService.getItem(LocalStorageKeys.cart) || null;
            setCart(generateFullCheckoutCart(updatedCart));
        };

        window.addEventListener("storageUpdate", handleStorageUpdate);
        return () => window.removeEventListener("storageUpdate", handleStorageUpdate);
    }, []);

    return (
        <>
            <FiShoppingCart className="me-2" /> Cosul meu
            {totalProducts > 0 && (
                <Badge pill bg="danger" className={styles.badge} style={{ position: "absolute", top: "5px", right: "20px" }}>
                    {Number(totalProducts).toFixed(0)}
                </Badge>
            )}
            {showCartDetails && (
                <div className={styles.additionalComponent}>
                    <FloatingCart cart={cart} />
                </div>
            )}
        </>
    );
};

export default NavbarCartButton;
