import { useCallback, useRef } from "react";

/**
 * Custom hook to debounce a function.
 * @param func The function to debounce (can be async).
 * @param delay The debounce delay in milliseconds.
 * @returns A debounced version of the function.
 */
function useDebounce<T extends (...args: any[]) => any>(func: T, delay: number): (...args: Parameters<T>) => Promise<ReturnType<T>> {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    return useCallback(
        async (...args: Parameters<T>): Promise<ReturnType<T>> => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            return new Promise<ReturnType<T>>((resolve) => {
                timeoutRef.current = setTimeout(async () => {
                    resolve(await func(...args));
                }, delay);
            });
        },
        [func, delay],
    );
}

export default useDebounce;
